@import './fonts.css'; /* must be first */
@import './algolia.css';
@import './termible.css';
@import './syntaxHighlighting.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  color-scheme: light;
  padding: 0;
  margin: 0;
}

html.dark {
  color-scheme: dark;
}

::selection {
  @apply bg-green-600 text-white; /* Consider switching to bg-blue which has good contrast in default shade */
  @apply dark:bg-green dark:text-black;
}

body {
  @apply bg-white text-black dark:bg-black dark:text-white;

  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.3s, background-color 0.3s;
  line-height: 1.4em;
  letter-spacing: 0.011em;
}

pre,
code {
  letter-spacing: 0.012em;
}

* {
  box-sizing: border-box;
}

/* Sticky footer 1/2: Mount point always takes 100% of the viewport height, utilize the space with flex display */
body > *:first-child {
  @apply min-h-screen flex flex-col;
}

@layer utilities {
  .site-container {
    @apply w-full mx-auto;

    padding: 0 16px;
  }

  .site-grid {
    @apply grid grid-cols-2 gap-x-4;
    @apply md:grid-cols-8;
    @apply lg:grid-cols-12 lg:gap-x-6;
  }

  .dotted-list {
    @apply list-outside list-disc pl-[20px];
  }

  .numbered-list {
    @apply list-outside list-decimal pl-[20px];
  }

  @screen md {
    .site-container {
      padding: 0 32px;
    }
  }

  @screen lg {
    .site-container {
      max-width: 1440px;
      padding: 0 55px;
    }
  }
}

/* Tracking debug: see `lib/ga-events.tx */

.debug-tracking [data-track]::before {
  content: attr(data-track);
  position: absolute;
  background: navy;
  color: #fff;
  font-size: 12px;
  font-family: monospace;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0.1em 0.2em;
  white-space: nowrap;
  z-index: 999;
  opacity: 0.7;
  margin-top: 2em;
  line-height: 1.3;
  text-transform: none;
}

.debug-tracking [data-track]:hover::before {
  opacity: 1;
  z-index: 999999;
}

kbd {
  font-family: 'Pie Symbols', 'PolySans-NeutralMono', 'Pie Body', sans-serif !important;
    white-space: nowrap;
}
td[align=center], th[align=center] {
  text-align: center !important;
}
th[align=center] {
  padding-left: 0!important;
  padding-right: 0!important;
}

/* Blog and docs images can be big, let’s give them a background color while loading */
img[src*='Blog'], img[src*='Docs'] {
  @apply bg-grey-800;
}

/* GIFs additionally get a background spinner */
img[src*='.gif'] {
  background-image: url('/Images/Spinner.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}
