/* Syntax highlighting */

.namespace {
  opacity: 0.7;
}

.token.prolog,
.token.doctype,
.token.cdata {
  @apply text-orange-700 dark:text-orange;
}

.token.prompt {
  @apply select-none text-pink;
}

.token.punctuation,
.token.comment {
  color: #999;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  @apply text-pink-700 dark:text-pink;
}

.token.boolean,
.token.number {
  @apply text-red-700 dark:text-red;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  @apply text-green-700 dark:text-green;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  @apply text-orange-700 dark:text-orange;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  @apply text-blue-700 dark:text-blue;
}

.token.function {
  @apply text-red-700 dark:text-red;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
