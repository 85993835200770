@font-face {
  font-family: 'Pie Headline';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/FKScreamer/FKScreamer-Bold.woff2') format('woff2'),
    url('/Brand/Fonts/FKScreamer/FKScreamer-Bold.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Body';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PolyPie/PolyPie-Slim.woff2') format('woff2'),
    url('/Brand/Fonts/PolyPie/PolyPie-Slim.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Body';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PolyPie/PolyPie-SlimItalic.woff2') format('woff2'),
    url('/Brand/Fonts/PolyPie/PolyPie-SlimItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Body';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PolyPie/PolyPie-Neutral.woff2') format('woff2'),
    url('/Brand/Fonts/PolyPie/PolyPie-Neutral.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Body';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PolyPie/PolyPie-NeutralItalic.woff2') format('woff2'),
    url('/Brand/Fonts/PolyPie/PolyPie-NeutralItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Body';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PolyPie/PolyPie-Median.woff2') format('woff2'),
    url('/Brand/Fonts/PolyPie/PolyPie-Median.woff2') format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Monospace';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PolyPieMono/PolyPie-SlimMono.woff2') format('woff2'),
    url('/Brand/Fonts/PolyPieMono/PolyPie-SlimMono.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Pie Symbols';

  /* noinspection CssUnknownTarget */
  src: url('/Brand/Fonts/PieSymbols/PPieSymbols-Regular.woff2') format('woff2'),
    url('/Brand/Fonts/PieSymbols/PieSymbols-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}
