/* Needed to properly show input animation */
.algolia-autocomplete {
  position: absolute !important;
}

/* Main dropdown wrapper */
.algolia-autocomplete .ds-dropdown-menu {
  @apply font-sans font-neutral tracking-base leading-loose min-w-[calc(100vw-20px)] md:min-w-[500px];

  /* place dropdown a bit lower than the input */
  margin-top: 10px;
}

/* Set proper background colors */
.algolia-autocomplete .ds-dropdown-menu [class^='ds-dataset-'] {
  @apply bg-grey-800 border-grey-800;
  @apply dark:bg-grey-100 dark:border-grey-200;
}

.algolia-autocomplete .algolia-docsearch-suggestion {
  @apply bg-grey-800 dark:bg-grey-100;
}

/* Upper triangle element */
.algolia-autocomplete .ds-dropdown-menu::before {
  @apply bg-grey-800 border-t-grey-800 border-r-grey-800;
  @apply dark:bg-grey-100 dark:border-t-grey-200 dark:border-r-grey-200;
}

/* Suggestions hover effect */
.algolia-autocomplete .ds-dropdown-menu .ds-suggestion.ds-cursor .algolia-docsearch-suggestion.suggestion-layout-simple,
.algolia-autocomplete
  .ds-dropdown-menu
  .ds-suggestion.ds-cursor
  .algolia-docsearch-suggestion:not(.suggestion-layout-simple)
  .algolia-docsearch-suggestion--content {
  @apply bg-blue-700 dark:bg-green-200;
}

/* Hide underline effect from hightlighted fragments to a background color change */
.algolia-autocomplete
  .algolia-docsearch-suggestion--category-header
  .algolia-docsearch-suggestion--category-header-lvl0
  .algolia-docsearch-suggestion--highlight,
.algolia-autocomplete
  .algolia-docsearch-suggestion--category-header
  .algolia-docsearch-suggestion--category-header-lvl1
  .algolia-docsearch-suggestion--highlight,
.algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  @apply bg-blue-700 dark:bg-green-200;

  box-shadow: none;
}

/* Main category */
.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  @apply font-median uppercase text-[14px] mb-[12px];
  @apply border-b-grey-700 dark:border-b-grey-200 text-white dark:text-black;
}

/* Category */
.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  @apply text-grey-100 dark:text-grey-800;
}

/* Category separator */
.algolia-autocomplete .algolia-docsearch-suggestion--content::before {
  @apply bg-grey-700 dark:bg-grey-200;
}

.algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column::after {
  /* hide separator on mobile, ::after is a proper pseudoselector as it's displayed on mobile instead of ::before */
  @apply hidden;
}

/* Title */
.algolia-autocomplete .algolia-docsearch-suggestion--title {
  @apply text-white dark:text-black;
}

/* Description description */
.algolia-autocomplete .algolia-docsearch-suggestion--text {
  @apply font-slim text-grey-200 dark:text-grey-700;
}

/* Highlighted text */
.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  @apply text-white bg-blue-700 dark:text-black dark:bg-green-200;
}

/* Display a shadow under Algolia logo on the light theme for better contrast */
.light .algolia-autocomplete .algolia-docsearch-footer--logo {
  filter: drop-shadow(0 8px 9px rgba(0, 0, 0, 0.8));
}
