.termible-widget .termible-widget-toolbar .termible-widget-close,
.termible-widget .termible-widget-toolbar .termible-widget-min,
.termible-widget .termible-widget-toolbar .termible-widget-max {
  @apply bg-grey-200 !important;
}

.terminal,
.termible-widget,
.termible-widget-terminal {
  @apply bg-[#262222] rounded-[5px] font-mono !important;
}

.xterm-viewport {
  @apply overflow-auto !important;
}

.xterm-viewport::-webkit-scrollbar {
  @apply w-[4px] z-0;
}

.xterm-viewport::-webkit-scrollbar-track {
  @apply bg-grey-700;
}

.xterm-viewport::-webkit-scrollbar-thumb {
  @apply bg-white rounded-full;
}

.termible-widget .termible-widget-powered {
  @apply z-[1] !important;
}

.termible-widget .termible-widget-terminal {
  @apply bottom-[12px] !important;
}
